<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <Header :headerData={} />

    <div class="panel">

      <H2>SUBMISSION</H2>

      <div class="sect" id="_1.Submission">&nbsp; Research Article</div>
      <div class="sect-desc">
        <p>THE YOUNG SCIENTIST (TYS) accepts submissions for either "original research" performed by undergraduate or high school student authors on a specific topic. $300 publication fee after November 30, 2024 will be requested once the article is accepted for publication after a review process. A copy of the printed journal will be mailed to authors's school once the articles are published.</p>
        <p>
          Students and mentors from any country can submit their advanced level research to be considered for publication in TYS. Students who receive awards at regional, national and international science fairs are highly encouraged to submit their work to reach out to a greater audience and take their research to a higher level of presentation by publishing with TYS. Research articles should include discussion and presentation of new experimental data and original research performed by the authors. Authors should make sure that their research has not been published elsewhere and they must point out what makes their research different than similar articles published in literature.
        </p>
      </div>

      <div class="sect" id="">&nbsp; Categories and Scope</div>
      <div class="sect-desc">
        <p>TYS's general scope is science, technology, engineering, and math. We accept articles in any of the these fields as long as they are original to the student author. Please visit the <a class="" href="#/forauthors">Author Guidelines</a> page.</p>
      </div>

      <div class="sect" id="">&nbsp; How to submit</div>
      <div class="sect-desc">
        <p>We accept manuscripts of any kind (doc, docx, pdf). We believe students should focus primarily on the scientific writing, rather than spending a great deal of time focusing on formatting.</p>
        <p>We highly encourage all students to read <a href="https://www.americanscientist.org/blog/the-long-view/the-science-of-scientific-writing">The Science of Scientific Writing Guide</a> and use it as they are drafting their manuscripts.</p>
        <p>When you are ready to submit (articles correctly placed in correct template and with proper citations), please EMAIL your article to info@ysassociation.org as a single document named as “LastName_FirstName” or “LastName_LastName_LastName” (if multiple authors). Any images (including figures, formulas) used in the article should be submitted separately in high resolution and .png format in the same email with the article.</p>
        <p>For submissions, send the paper and any images used in paper as a single email to info@ysassociation.org</p>
      </div>

    </div>
  
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
  import Header from '../components/Header.vue'
  import Footer from "../components/Footer.vue";
 
  export default {
    name: "Home",
    data() {
      return {

      };
    },    
    components: {
      Footer,
      Header,
      // SectTurnBook,
    },

    mounted() {
    },

    methods: {
    }
  
  }

</script>


<style lang="less" scoped>
.container {
  // margin-bottom: 40px;
  padding-bottom: 0;
}
.panel{
  max-width: 1024px;
  margin: 10px auto;
  padding-left: 10px;
  text-align: left ;
  line-height: 1.5 ;
  .sect {      
    // font-size: px;
    font-weight: 700;
    color: #fff;
    background-color: #8dc641;
    // letter-spacing: 0px;
  }
  .sect-desc{
    text-align: left;
    line-height: 1.25 ;
    padding: 5px 2px;
    font-size: 14px;
    color:#333;
    // p::before{
    //   content: '•';
    //   margin-right: 5px;
    // }
  }
  > p{
    font-size: 14px;
    font-weight: 200;
  }
  > li{
    font-size: 12px;
    color: #123d80;
  }
  > li::marker{
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
  }
}

.txt-link{
  font-size: 14px;
}
.txt-red{
  color: red;
}

.main {
  color: #333;
  padding: 0 10%;
  // 中区域的左侧文字区
}



//显示时按钮样式
.el-button--primary { //需要更改的按钮类型
  background: #2836e0 !important;
  border-color: #2836e0 !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #005eff !important;
  border-color: #005eff !important;
  color: #FFF !important;
}

@media (max-width: 550px) {
  .panel{
    margin: 50px 10px !important;
    h1{
      font-size: 36px !important;
    }
  }
}
</style>
